import * as React from "react"
import {css, useTheme} from "@emotion/react";
import {Link} from "gatsby";

const NotFoundPage = () => {
  const theme = useTheme();
  return (
    <main css={css`
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    `}>
      <title>Not found</title>
      <div css={css`
        color: ${theme.color.danger};
      `}>
        404 - Page not found!
      </div>
      <div css={css`
        margin-top: ${theme.margin.big}px;
      `}>
        Why should you never eat a clock? Because it is a time-consuming process.
      </div>
      <div css={css`
        margin-top: ${theme.margin.big}px;
      `}><Link to="/">Return Home</Link></div>
    </main>
  )
}

export default NotFoundPage
